<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />

        <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">                 
            <template v-for="(item, key) in list_check_box">                 
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                    
                    <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->

                    <!-- Section Detail Start -->        

                    <!-- detail_indication  start-->  
                    <Indication :same_as_ma="data.spain.sane_as_ma" v-if="key == 'detail_indication' && item.show"/>              
                    <!-- detail_indication end -->

 
                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                    <!-- detail_key_documents start  -->                   
                   <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>
                    <!-- detail_key_documents end -->
 
                    <!-- detail_rationale_and_commentary_original -->
                    <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />  
                       
                    <!-- @todo detail_clinical_trials   -->

                     <!-- information_general start-->                 
                    <div class="box-toggle textJustify"  v-else-if="key == 'detail_general_information' && item.show" >                  
                        <span><a class="title title-tb" style="cursor:pointer;"  @click="marketing_authorization_date_Show = !marketing_authorization_date_Show">                             
                            {{$t('marketing_authorization_date')}} <em :class="marketing_authorization_date_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>                          
                        </a> </span>
                        <div class="text textJustify" v-if="marketing_authorization_date_Show"> 
                           {{DDMMMYY(data.spain.marketing_authorization_date)}} 
                        </div>
                    </div>  
                    <!--information_general end-->
                
                   
                    <!--Evidence in brief start-->
                    <div class="content-onglets" v-else-if="key == 'detail_evidence_in_brief_original' && item.show" >                           
                        <div v-html="data.spain['evidence_in_brief'+suffix_txt] ? data.spain['evidence_in_brief'+suffix_txt] : '-'"></div>                   
                    </div>
                    <!--Evidence in brief end-->

                    <!-- background start -->
                    <div class="content-onglets" v-else-if="key == 'detail_background_original' && item.show" >   
                        <div v-html="data.spain['background'+suffix_txt] ? data.spain['background'+suffix_txt] : '-'"></div>
                    </div>
                    <!-- background end -->

                    <!--clinical_evidence start-->
                    <div class="content-onglets" v-else-if="key == 'detail_clinical_evidence_original' && item.show" >   
                        <div v-html="data.spain['clinical_evidence'+suffix_txt] ? data.spain['clinical_evidence'+suffix_txt] : '-'"></div> 
                    </div>
                    <!--clinical_evidence end-->

                    <!--Post marketing study start-->
                    <div class="content-onglets" v-else-if="key == 'detail_post_marketing_study_original' && item.show" >  
                        <div v-html="data.spain['post_marketing_study'+suffix_txt] ? data.spain['post_marketing_study'+suffix_txt] : '-'"></div> 
                    </div>
                    <!--Post marketing study start-->

                    <!--Evidence for clinical efficacy start-->
                    <div class="content-onglets "  v-else-if="key == 'detail_SpainPrimaryEndpoint' && item.show" >   
                        <table class="comparateur2 border-top-gray text-left">
                            <tbody>
                                <tr>
                                    <th>Acceptance of primary endpoint</th>
                                    <td>{{check_empty(data.spain.acceptance_of_primary_endpoint) }}</td>
                                </tr>
                                <tr>
                                    <th>Same effect as comparator</th>
                                    <td>{{check_empty(data.spain.same_effect_as_comparator) }}</td>                                
                                </tr>
                                <tr>
                                    <th>Same response rate as comparator</th>
                                    <td>{{check_empty(data.spain.same_response_rate_as_comparator) }}</td>                                                                
                                </tr>
                                <tr>
                                    <th>Safety concerns</th>
                                    <td>{{check_empty(data.spain.safety_concerns) }}</td>                                                                
                                </tr>
                                <tr>
                                    <th>Same safety profile/tolerability as comparator</th>
                                    <td>{{check_empty(data.spain.same_safety_profile) }}</td>                                                                
                                </tr>
                                <tr>
                                    <th>QoL data submitted</th>
                                    <td>{{check_empty(data.spain.qol_data_submitted) }}</td>                                                                
                                </tr>
                                <tr>
                                    <th>Acceptance of QoL data</th>
                                    <td>{{check_empty(data.spain.acceptance_of_qol_data) }}</td>                                                                
                                </tr>
                                <tr>
                                    <th>Indirect treatment comparison</th>
                                    <td>{{check_empty(data.spain.indirect_treatment_comparison) }}</td>                                                                
                                </tr>
                                <tr>
                                    <th>Acceptance of indirect treatment comparison</th>
                                    <td>{{check_empty(data.spain.acceptance_of_indirect_treatment_comparison) }}</td>                                                                
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <!--Evidence for clinical efficacy start-->


                    <!--Efficacy start-->
                    <div class="content-onglets" v-else-if="key == 'detail_efficacy_eng' && item.show" >  
                        <div v-html="data.spain['efficacy'+suffix_txt_eng] ? data.spain['efficacy'+suffix_txt_eng] : '-'"></div>                   
                    </div>
                    <!--Efficacy end-->

                    <!--Safety start-->
                    <div class="content-onglets" v-else-if="key == 'detail_safety_eng' && item.show" >  
                        <div v-html="data.spain['safety'+suffix_txt_eng] ? data.spain['safety'+suffix_txt_eng] :'-'"></div>                   
                    </div>
                    <!--Safety end-->
                    
                    <!--Cost-effectiveness start-->
                    <div class="content-onglets" v-else-if="key == 'detail_cost_effectiveness_eng' && item.show" >  
                       <div v-html="data.spain['cost_effectiveness'+suffix_txt_eng] ? data.spain['cost_effectiveness'+suffix_txt_eng] :'-'"></div>                        
                    </div>
                    <!--Cost-effectiveness end-->

                    <!-- Other start  -->
                    <div class="content-onglets" v-else-if="key == 'detail_other_eng' && item.show" >  
                        <div v-html="data.spain['other'+suffix_txt_eng] ? data.spain['other'+suffix_txt_eng] :'-'"></div>                        
                    </div>
                    <!-- Other end  -->

                    <!-- detail_SpainPrice  start-->
                    <div class="content-onglets " v-else-if="key == 'detail_SpainPrice' && item.show" > 
                        <table class="comparateur2  text-center" v-if="data.spain.spain_price.length > 0 || data.spain.same_price_as_comparators">
                            <thead>
                                <tr>
                                    <th width="40%" style="text-align: left;">{{$t('description_therapy')}}</th>      
                                    <th width="30%" >{{$t('Price')}}</th> 
                                    <th width="30%" >{{$t('Price incl')}}</th> 
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(items,keys) in data.spain.spain_price" :key="'SpainPrice'+keys">                        
                                    <td>{{check_empty(items.description_therapy)}}</td>    
                                    <td>{{check_empty(items.price)}}</td> 
                                    <td>{{check_empty(items.price_incl)}}</td> 
                                </tr>
                                <tr v-if="data.spain.same_price_as_comparators">
                                    <th width="40%">{{$t('Same price as comparators')}}</th>
                                    <td width="60%" colspan="2">{{data.spain.same_price_as_comparators}}</td>   
                                </tr>
                            </tbody>
                        </table>
                        <table class="comparateur2  text-center" v-if="data.spain.spain_cost_treatment.length > 0">
                            <thead>
                                <tr>
                                    <th width="40%" style="text-align: left;">{{$t('description_therapy')}}</th>    
                                    <th width="30%" >{{$t('Cost of treatment')}}</th>
                                    <th width="30%" >{{$t('Cost of treatment incl')}}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(items,keys) in data.spain.spain_cost_treatment" :key="'SpainCostTreatment'+keys">                        
                                    <td>{{check_empty(items.description_therapy)}}</td>
                                    <td>{{check_empty(items.cost_of_treatment)}}</td>
                                    <td>{{check_empty(items.cost_of_treatment_incl)}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <!-- 'sub_detail/evaluation_economic.ctp'  -->
                    <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.spain.evaluation_economic" />

                    <!-- 'sub_detail/EconomicEvaluation.ctp' -->
                    <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />
                        
                    <!--BudgetImpact start-->
                    <BudgetImpact :data="data.spain['budget_impacts']" v-else-if="key == 'detail_BudgetImpact' && item.show" /> 
                    <!--BudgetImpact end-->          

                    <!-- detail_target_population_original start -->
                    <div class="content-onglets"  v-else-if="key == 'detail_target_population_original' && item.show" >
                        <p v-html="data.spain['target_population'+suffix_txt]"></p>
                        <div class="box-toggle textJustify">
                            <template v-if="data.spain.target_population_number" >
                                    <a  class="title title-tb" style="cursor: pointer;" @click="target_population_number_Show = !target_population_number_Show">                                
                                        {{$t('Target population number')}}
                                        <em :class="target_population_number_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>                                             
                                    </a>                     
                                    <div v-if="target_population_number_Show">
                                        {{data.spain.target_population_number}}                   
                                    </div>
                            </template>
                        </div>
                    </div>
                    <!-- detail_target_population_original end -->

                    <!--Comparator start-->
                    <div class="content-onglets "  v-else-if="key == 'detail_comparator_original' && item.show" >
                        <div v-html="data.spain['comparator'+suffix_txt]"></div>
                        <div class="box-toggle textJustify" v-if="data.spain.spain_comparator_name && data.spain.spain_comparator_name.length > 0">
                            <a  class="title title-tb" style="cursor: pointer;"  @click="SpainComparatorName_Show = !SpainComparatorName_Show">                                
                                {{$t('spain.Comparator name')}}
                                <em :class="SpainComparatorName_Show ? 'fa fa-angle-up icon-arrow-updown' : 'fa fa-angle-down icon-arrow-updown'"></em>                                             
                            </a>  
                            <div v-if="SpainComparatorName_Show">
                                <table class="comparateur2">
                                    <thead>
                                        <tr>
                                            <th >{{$t('spain.Comparator name')}}</th>   
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(items, keys) in data.spain.spain_comparator_name" :key="'spain_comparator_name'+keys">
                                            <td>{{items.comparator_name ? items.comparator_name : "-"}}</td>                                        
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <!--Comparator end-->

                    <!--Final Considerations start-->
                    <div class="content-onglets" v-else-if="key == 'detail_final_consideration_eng' && item.show" >
                        <div v-html="data.spain['final_consideration'+suffix_txt_eng] ? data.spain['final_consideration'+suffix_txt_eng] : '-'"></div>
                    </div>
                    <!--Final Considerations end-->


                    <!-- SpainMea start-->
                    <div class="content-onglets " v-else-if="key == 'detail_SpainMea' && item.show" >
                        <table class="comparateur2">
                            <thead>
                                <tr>
                                    <th width="33%" >Managed entry agreement</th>
                                    <th width="33%" >Discount rate RDL 8/2010</th>              
                                    <th width="33%" >Start date</th>                       
                                </tr>
                            </thead>
                            <tbody>   
                                <tr v-for="(items,keys) in data.spain.spain_mea" :key="'SpainMea'+keys"> 
                                    <td style="vertical-align: middle">{{check_empty(items.mea)}} </td>
                                    <td style="vertical-align: middle">{{check_empty(items.discount_rate)}} </td>
                                    <td style="vertical-align: middle">{{DDMMMYY(items.start_date_mea)}} </td>
                                </tr>    
                            </tbody>
                        </table>
                    </div>
                    <!-- SpainMea end-->

                <!-- detail_clinical_trials  -->
                <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>

                </div>
            </template>
           
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
			
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue'
import Conclusion from '../conclusion.vue'
import BudgetImpact from '../budget_impact.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import TreatmentLine from '../treatment_line.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import { DDMMMYY, check_empty, check_detail_conclusion, key_documents } from '../../../utils'
import Indication from '../Indication.vue'

export default {
    name: 'spain',
    components : {
        LeftSection, 
        Conclusion,
        BudgetImpact,
        EvaluationEconomic,
        EconomicEvaluation,
        TreatmentLine,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_general_information : {'title' : 'general_information', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_indication : {'title' : 'Indication under review', 'show' : true, 'enable' : false, 'orange_text':false},
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_Conclusion : {'title' : 'Rationale and commentary', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_evidence_in_brief_original : {'title' : 'Evidence in brief', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_background_original : {'title' : 'Background', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_clinical_evidence_original : {'title' : 'Clinical evidence', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_post_marketing_study_original : {'title' : 'Post marketing study', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_SpainPrimaryEndpoint : {'title' : 'Evidence for clinical efficacy', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_efficacy_eng : {'title' : 'Efficacy', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_safety_eng : {'title' : 'Safety', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_cost_effectiveness_eng : {'title' : 'Cost-effectiveness', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_other_eng : {'title' : 'Other', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_SpainPrice : {'title' : 'Cost', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_BudgetImpact : {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':true},
                detail_target_population_original :{'title' : 'Target population', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_comparator_original :{'title' : 'Comparator', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_final_consideration_eng :{'title' : 'Final Considerations', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_SpainMea :{'title' : 'Managed Entry Agreement (MEA)', 'show' : true, 'enable' : false, 'orange_text':false},
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false},
            },
            suffix_txt : '',
            suffix_txt_eng : '',
            // suffix_txt_lang : '',
            sane_as_ma_Show : false,
            marketing_authorization_date_Show :false,
            target_population_number_Show : false,
            SpainComparatorName_Show : false,
             

        }
    },
    created(){
        this.suffix_txt = '_original'
        this.suffix_txt_eng = '_eng' 
        if (this.$i18n.locale == 'es'){
            this.suffix_txt = ''
            this.suffix_txt_eng = '_span'          
        }
        // condition to show check box in left section [enable]
        if(this.data && this.data.spain){

            if(this.data['indication_original'] || this.data['indication_en'])
            { 
                this.list_check_box['detail_indication'].enable = true
            }

            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }    
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }  
            if(this.check_detail_conclusion(this.data, this.$i18n.locale) ||
                this.data.spain['limitation'] ||
                this.data.spain['rationale_and_commentary'+this.suffix_txt]) 
            { 
                    this.list_check_box['detail_Conclusion'].enable = true
            }

            if (this.data.spain.marketing_authorization_date) {
                this.list_check_box['detail_general_information'].enable = true
            }
            if (this.data.spain['evidence_in_brief'+this.suffix_txt]){
                this.list_check_box['detail_evidence_in_brief_original'].enable = true
            }
            if (this.data.spain['background'+this.suffix_txt]){
                this.list_check_box['detail_background_original'].enable = true
            }

            if (this.data.spain['clinical_evidence'+this.suffix_txt]) {
                this.list_check_box['detail_clinical_evidence_original'].enable = true
            }
                            
            if (this.data.spain['post_marketing_study'+this.suffix_txt]) {                
                this.list_check_box['detail_post_marketing_study_original'].enable = true
            }

            if (this.data.spain['acceptance_of_primary_endpoint']
            || this.data.spain['same_effect_as_comparator']
            || this.data.spain['same_response_rate_as_comparator']
            || this.data.spain['safety_concerns']
            || this.data.spain['same_safety_profile']
            || this.data.spain['qol_data_submitted']
            || this.data.spain['acceptance_of_qol_data']
            || this.data.spain['indirect_treatment_comparison']
            || this.data.spain['acceptance_of_indirect_treatment_comparison']
            
            ) {
                this.list_check_box['detail_SpainPrimaryEndpoint'].enable = true
            }
            if (this.data.spain['efficacy'+this.suffix_txt_eng]) {
                this.list_check_box['detail_efficacy_eng'].enable = true
            }
            if (this.data.spain['safety'+this.suffix_txt_eng]){
                this.list_check_box['detail_safety_eng'].enable = true
            }
            if (this.data.spain['cost_effectiveness'+this.suffix_txt_eng]){
                this.list_check_box['detail_cost_effectiveness_eng'].enable = true
            }            
            if (this.data.spain['other'+this.suffix_txt_eng]){
                this.list_check_box['detail_other_eng'].enable = true
            }
            if (this.data.spain['spain_price'].length > 0 || this.data.spain['spain_cost_treatment'].length > 0) {
                this.list_check_box['detail_SpainPrice'].enable = true
            }

            if(this.data.spain.evaluation_economic.length > 0) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }
            
            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false                    
            }               
            if(this.data.spain['budget_impacts'] && this.data.spain['budget_impacts'].length > 0 ) { 
                this.list_check_box['detail_BudgetImpact'].enable = true
            }
            
            if (this.data.spain['target_population'+this.suffix_txt] || this.data.spain['target_population_number']) {                    
                this.list_check_box['detail_target_population_original'].enable = true
            }

            if (this.data.spain['comparator'+ this.suffix_txt] || this.data.spain['spain_comparator_name'].length > 0){
                this.list_check_box['detail_comparator_original'].enable = true
            }

            if (this.data.spain['final_consideration'+this.suffix_txt_eng]) {
                this.list_check_box['detail_final_consideration_eng'].enable = true
            }

            if (this.data.spain['spain_mea'].length > 0){
                this.list_check_box['detail_SpainMea'].enable = true
            }

            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
        }
    },
    methods : {
        DDMMMYY,
        check_empty,
        check_detail_conclusion,
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },        			
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        } 
    }
}
</script>

<style scoped>

a{
    color: #000;
}

.box-toggle.icon-arrow-updown {
    font-size: 16px;
    right: -14px;
    color: #232426;
    margin-left:5px;
}
.box-toggle a:hover i {
    color: #ff6300;
}
.fa-angle-up {
    color : #ff6300;
}
</style>